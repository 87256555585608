<template>
  <div class="place-page container-fluid">

    <div class="mt-3">
      <router-link to="/place/map" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div class="search-block mt-3">
      <input class="form-control" placeholder="Find a place for your next exercise" v-model="search" />
    </div>

    <div class="place-list" v-if="getPlaceListBySearch.length > 0">
      <div class="card mb-4 rounded-3" v-for="placeData in getPlaceListBySearch" :key="placeData.id" @click="doPlaceView(placeData)">
        <div class="card-body">
          <span class="price-block">
            <span class="fa fa-circle-o"></span>
            {{ this.getPrice(placeData.price) }}{{ this.getCurrencySymbol() }}
          </span>
          <div class="distance" v-if="placeData?.distance">
            {{ this.getFormatDistance(placeData.distance) }}
          </div>
          <div class="title">
            {{ placeData.title }}
          </div>
          <div class="work-time" v-html="this.decode(this.getFormatText(placeData.workTime))"></div>
          <div class="address" v-html="placeData.address"></div>
          <div class="mb-1" v-if="placeData.text" v-html="this.decode(this.getFormatText(placeData.text))"></div>
          <div class="tag-list" v-if="placeData.placeTagLinkList.length > 0">
            <span class="tag" v-for="placeTagLinkData in placeData.placeTagLinkList" :key="placeTagLinkData.id">
              {{ placeTagLinkData.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
        No records found
    </div>

  </div>
</template>

<script>
import PlaceMixin from '@/modules/place/mixins/PlaceMixin';
import BaseMixin from "@/modules/base/mixins/BaseMixin";

export default {

  mixins: [PlaceMixin, BaseMixin],

  data: () => ({
    search: '',
    placeList: []
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.initGeoLocation(false);

    await this.doPlaceIndex();
  },

  computed: {

    getPlaceListBySearch() {

      return this.placeList.filter(placeData => {

        placeData.distance = this.getPlaceDistance(placeData);

        return placeData.title?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            placeData.text?.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            placeData.address?.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      })
    }
  },

  methods: {

    async doPlaceIndex() {

      let apiHolder = await this.sendApiGet(['api/place/index']);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeList)
        this.placeList = apiHolder.data.placeList;
    },

    async doPlaceView(placeData) {

      await this.$router.push({path: '/place/view/' + placeData.id, query: {back: 'list'}});
    }

  }

}
</script>

<style scoped>

.search-block {
  padding-bottom: 30px;
}

.place-list .card {
  background: #343434;
  color: #FFF;
  margin-bottom: 30px !important;
  cursor: pointer;
}

.place-list .card-body {
  padding-top: 5px;
  padding-bottom: 10px;
  position: relative;
}

.place-list .card .price-block {
  background: #FFFA83;
  position: absolute;
  top: -15px;
  left: 15px;
  line-height: 1.5em;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  border-radius: 38px;
  padding: 4px 10px 4px 5px;
  display: flex;
}

.place-list .card .price-block .fa {
  font-size: 20px;
  padding-right: 8px;
}

.place-list .card .title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

.place-list .card .distance {
  color: #EBEAEC;
  font-size: 13px;
  float: right !important;
  font-weight: 500;
}

.place-list .card .work-time {
  color: #FFFA83;
  font-weight: 500;
}

.place-list .card .tag-list {
  padding-top: 10px;
  padding-bottom: 10px;
}

.place-list .card .tag-list .tag {
  background: #424242;
  border-radius: 38px;
  color: #2CDAE5;
  font-weight: 500;
  padding: 5px 12px;
  margin-right: 5px;
}

</style>